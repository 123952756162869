<template>


  <div :class="{'title_rooms': true, 'worked': worked}">
 

    <ChatClose
      :enable="enable"
      @close="close"
      />

    Chat
    <div v-if="useAuth().isAuth() && useAuth().isVerifiedEmail()" class="add_group" @click="addGroup">
      <i class="icon-users"></i>
      Create group chat
    </div>
  </div>
 
</template>


<script setup>

const emits = defineEmits(['addgroup', 'close'])

const props = defineProps({
  worked: {
    type: Boolean
  },
  enable: {
    type: Boolean
  },
})


const addGroup = function(){
  emits('addgroup')
}

const close = function(){
  emits('close')
}

</script>
 


<style scoped>
  
 .title_rooms .add_group {
  font-size: 1rem;
  color: #6d6d6d;
  cursor: pointer;
}

 .title_rooms .add_group:hover {
  color: var(--un-text-color);
}

.is_loading {
  display: none !important;
  position: absolute !important;
  right: 23px;
  top: 27px !important;
  margin-right: 0px !important;
}
 .title_rooms {
  text-align: center;
  padding: 15px 20px;
  font-size: 1.3rem;
  position: relative;
}
  
@media (max-width: 768px) {
   
  .title_rooms.worked{  
    display: none;
  }
}

</style>