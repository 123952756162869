<template>

  <div 
    :style="{ 'margin-bottom': usePageBottomMargin().value + 'px' }"
    :class="{'toggle': true, 'enable': props.enable, 'enable_new': useUserUpdatesChat().value && !props.enable}" 
    @click="toggle"
    >
    <i class="icon-angle-right icright rndb"></i>
    <i :class="'icon-message icsend rndb ' + (useUserUpdatesLoading().value ? 'floading' : '')"></i>    
  </div>
  
</template>


<script setup>

const emits = defineEmits(['toggle'])

const props = defineProps({
  enable: {
    type: Boolean
  }
})

const toggle = function(){
  console.log(props.enable)
  emits('toggle', !props.enable);
  useUserUpdatesChat().value = 0;
}

</script>
 


<style scoped>
  
/* TOGGLE */
.back_mobile {
  display: none;
  z-index: 101;
  padding: 18px 10px;
  position: absolute;
  color: gray;
}

.toggle {
  position: absolute;
  bottom: 14px;
  left: -49px;
  width: 120px; 
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}


.toggle.enable {
  position: absolute;
  bottom: 14px;
  left: -46px;
}

.toggle:hover {
  /* left: -55px; */
}

.toggle.enable:hover {
  /* left: -60px; */
}

.toggle>.rndb {
  padding: 10px;
  background-color: var(--un-background-color-gray-dark);
  border-radius: 40px;
  color: var(--un-text-color);
  width: 60px;
  height: 37px;
  text-align: left;
  padding-left: 15px;
    /* box-shadow: 0px 0px 10px rgb(0 0 0 / 24%); */
}

.toggle.enable_new>.rndb {
  color: white;
  background-color: var(--un-firm);
}

.toggle.enable>.rndb {
  border-radius: 40px;
  width: 38px;
}

.toggle>.rnd {
  display: none;
  background-color: var(--un-background-color-gray-dark);
  border-radius: 40px;
  color: var(--un-text-color);
  width: 15px;
  height: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.51);
  position: absolute;
  right: 0px;
  top: 17px;
}

.toggle>.icright {
  display: none;
}

.toggle>.icsend {
  display: inline-block;
}

.toggle.enable>.icright {
  display: inline-block;
  padding-left: 12px;
}

.toggle.enable>.icsend {
  display: none;
}

@media (min-width: 768px) {
  .toggle {
    margin-bottom: 0!important;
  }
}
</style>