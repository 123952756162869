<template>


  <div class="search">
 
    <div class="search_add_user">
      <input type="text" @keyup.passive="searchUser" v-model="search_user_name" placeholder="Start typing user name...">      
    </div>
 
    <div 
      v-if="search_data.length > 0 && search_data?.length"
      class="users">
      <div class="user" v-for="user in search_data" :key="user.id">
        <NuxtLink :to="user.link" class="name" @click="linkUser"><img class="avatar" :src="user.avatar_little" /></NuxtLink>
        <NuxtLink :to="user.link" class="name" @click="linkUser">{{ user.name }}</NuxtLink>
        <div class="act">
          <UiButton 
            name="Chat"
            type="secondary"
            @click="openChat(user)"
            /> 
        </div>
      </div>
    </div>

    <UiEmpty
      v-else-if="search_user_name.length > 0 && !isLoading"
      />


  </div>
  
</template>


<script setup>

import debounce from 'lodash/debounce';
import { useClipboard } from '@vueuse/core'


const emits = defineEmits(['select'])
const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const { $api, $popup, hooks } = useNuxtApp()
const props = defineProps({
  room: {
    type: Object
  }
})
const search_user_name = ref('')
const search_data = ref([])
const isLoading = ref(false)
  
 
const searchUser = debounce((event) => {
  search_user_name.value = event.target.value;
  search_data.value = [];
  if(search_user_name.value.length > 0){    
    searchUsers();
  }

}, 300);
 
const openChat = function(user){
  // emits('select', user.id)

  hooks.callHook('chat:start', user.id).catch(() => {
    console.log('chat:start hook');    
  })
}
 
const linkUser = function(){
  
  hooks.callHook('chat:close').catch(() => {
    console.log('chat:close hook');    
  })
}

const searchUsers = async function(){
  isLoading.value = true;
  var data = await $api.getGrowers({
    keyword: search_user_name.value,
    start: 0,
    limit: 40,
  })
  isLoading.value = false;
  console.log(data);
  // users.value.push(...data);
  if(data.items?.length)
  search_data.value = data.items;
}
 

// const loadData = async function(){
//   console.log('chat users');
//   var data = await $api.getChatRoomUsers(props.room.id, 0, 200)
//   return data? data: [];
// }


// const { pending: is_loading,  data: search_data } = await useLazyAsyncData('search_data', async () => await loadData())



// watch(() => search_user_name, (newId, oldId) => {
//   users.value = [];
//   searchUsers();
// }, { deep: true })


onUnmounted(() => {
  search_data.value = [];
})
</script>
 


<style scoped>
  

/* MANAGE */

.search{
  padding: 1rem;
  padding-top: 0;
}

.manage>.title {
  text-align: center;
  padding: 10px 20px 10px 20px;
  font-size: 1.3rem;
}
.manage>.title i{
  font-size: 1.1rem;
}

.users{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}
.users .user{
  display: flex;
  align-items: center;
  gap: 10px;
}
.users .user .avatar{
  border-radius: 100px;
  aspect-ratio: 1/1;
  width: 40px;
}
.users .user .name{

}
.users .user .act{
  margin-left: auto;
}

  
</style>