<template>


  <div class="manage">

    <ChatClose
      @close="close"
      />

    <div class="title">
      <i class="icon-setting"></i> Manage Group
    </div>

    <div class="settings">

      <div class="setting">                  
        <input type="text" @change="saveName" v-model="props.room.name" placeholder="Group name">        
      </div>

      <div class="setting">

        <div class="name"><i class="icon-lock"></i> Access to group</div>

        <div class="set">
          <span v-if="props.room.is_private">Private</span>
          <span v-else>Public</span>
          <UiToggle 
            v-model="props.room.is_private"
            @update:modelValue="savePrivate"
            />
        </div>
      </div>

      <div class="setting">
        <div class="name"><i class="icon-share"></i> Link</div>
        <div class="set">
          <span class="copy_link" @click="copyLink">Copy link</span>
        </div>
      </div>

    </div>

    <div class="title">
        <i class="icon-users"></i> Users
    </div>


    <UiInput
      label="Start typing user name"
      :text="search_user_name"
      :timeout="500"
      type="text"
      @change:text="searchUser"
      />
        
    <!-- <div class="search_add_user">
      <input type="text" @keyup.passive="searchUser" v-model="search_user_name" placeholder="User name">      
    </div> -->
 
    <ChatManageUsersSearch
      v-if="search_user_name"
      :search="search_user_name"
      :room="props.room"
      />
 
    <ChatManageUsers
      v-else
      :room="props.room"
      />

  </div>
  
</template>


<script setup>


import { useClipboard } from '@vueuse/core'

const emits = defineEmits(['close'])
const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const { $api, $popup } = useNuxtApp()
const props = defineProps({
  room: {
    type: Object
  }
})
var search_user_name = ref('')
var users = ref([])

const saveName = function(){
  $api.patchChatRoom(props.room.id, {name: props.room.name})
  .then((res) => {                          
    $popup.success('Saved')
  })
  .catch((err) => {
    $popup.error('Error')
  });
}

const savePrivate = function(){
  console.log('props.room.is_private')
  console.log(props.room.is_private)
  $api.patchChatRoom(props.room.id, {is_private: props.room.is_private})
  .then((res) => {                          
    $popup.success('Saved')
  })
  .catch((err) => {
    $popup.error('Error')
  });
}

const copyLink = function(){
  copy(document.location.protocol + '//' + document.location.host + '#conversation_' + props.room.id)
}

const close = function(){
  emits('close')
}


const searchUser = (text) => {
  // search_user_name.value = event.target.value;

  search_user_name.value = text;

  console.log(search_user_name.value)
}
 



const loadUsers = async function(){
  var data = await $api.getChatRoomUsers(props.room.id, 0, 20)
  console.log(data);
  users.value.push(...data);
}

</script>
 


<style scoped>
  

/* MANAGE */
.manage {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
  background-color: var(--un-background-color);
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}

.back_mobile,
.room,
.rooms,
.title_rooms {
  /* display: none; */
}

.manage {
  /* display: none; */
}

.manage>.close_manage {
  position: absolute;
  cursor: pointer;
}

.manage>.title {
  text-align: center;
  padding: 10px 20px 10px 20px;
  font-size: 1.3rem;
}
.manage>.title i{
  font-size: 1.1rem;
}
.manage .setting input[type="text"]{
  width: calc(100%);
}
.manage>.settings {
  margin-bottom: 12px;
}

.manage>.settings>.setting {
  padding: 7px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage>.settings>.setting>.name {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}

.manage>.settings>.setting>.set {
  width: 48%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.manage>.settings>.setting>.set>.copy_link {
  border-bottom: 1px #484848 dashed;
}

.manage>.search_add_user {
  margin-bottom: 20px;
}

  
</style>