<template>

  <div class="users_list">
 



    <SkBasicRows 
      v-if="is_loading && start == 0" 
      />
    <div v-else-if="users_data.items_user?.length" class="users">
      <div class="user" v-for="user in users_data.items_user" :key="user.id">
        <img class="avatar" :src="user.avatar_small" />
        <div class="name">{{ user.name }}</div>
        <div class="act">
          <UiButton 
            v-if="user.is_joined"
            name="Delete"
            @click="remove(user)"
            />
          <UiButton 
            v-else
            type="primary"
            name="Invite"
            @click="add(user)"
            />
        </div>
      </div>
    </div>


    <client-only>        
      <InfinityScroll 
        v-if="users_data.items_user?.length >= limit"
        :is-loading="is_loading"
        ref="el_infinity_scroll"/>
    </client-only>


  </div>

  
</template>


<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const { $api, $popup } = useNuxtApp()
const el_infinity_scroll = ref(null)
const props = defineProps({
  room: {
    type: Object
  },
  search: {
    type: String
  }
})

var start = ref(0)
var limit = ref(20)
var is_ended = ref(false)

 

const remove = function(user){
  $api.deleteChatRoomUser(props.room.id,  user.id)
  .then((res) => {                          
    $popup.success('Deleted')
    user.is_joined = false;
  })
  .catch((err) => {
    $popup.error('Error')
  });
}

const add = function(user){
  $api.postChatRoomUser(props.room.id,  user.id)
  .then((res) => {                          
    $popup.success('Invited')
    user.is_joined = true;
  })
  .catch((err) => {
    $popup.error('Error')
  });
}

const loadData = async function(){
  console.log('chat users');
  var data = await $api.getChatRoomUsersSearch(
      props.room.id, 
      props.search, 
      start.value, 
      limit.value)
  return data;
}


const { pending: is_loading,  data: users_data } = await useLazyAsyncData('users_data', async () => await loadData())


watchArray([start, props], async ([new_start, new_props], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    users_data.value.items_user = [];
    is_ended.value = false;
  } 
  
  is_loading.value = true;
  var dt = await loadData();

  if(dt.length == 0)
    is_ended.value = true;  

  users_data.value.items_user = [...users_data.value.items_user, ...dt.items_user]

  is_loading.value = false;

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


onBeforeUnmount(() => {
  users_data.value.items_user = [];
})

</script>
 


<style scoped>
  

  
.users{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}
.users .user{
  display: flex;
  align-items: center;
  gap: 10px;
}
.users .user .avatar{
  border-radius: 100px;
  aspect-ratio: 1/1;
  width: 40px;
}
.users .user .name{

}
.users .user .act{
  margin-left: auto;
}

  
</style>