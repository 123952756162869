<template>


  <div class="manage">

    <ChatClose
      @close="close"
      />

    <div class="title">
        <i class="icon-users"></i> Users
    </div>

    <div class="search_add_user">
      <input type="text" @keyup.passive="searchUser" v-model="search_user_name" placeholder="User name">      
    </div>
 
    <div class="users">
      <div class="user" v-for="user in users.items" :key="user.id">
        <img class="avatar" :src="user.avatar_little" />
        <div class="name">{{ user.name }}</div>
        <div class="act">
          <UiButton 
            v-if="!invited_data.find((invited_user) => invited_user.id == user.id)"
            name="Invite"
            type="primary"
            @click="invite(user)"
            />
          <UiButton 
            v-if="invited_data.find((invited_user) => invited_user.id == user.id)"
            name="Delete"
            @click="remove(user)"
            />
        </div>
      </div>
    </div>


  </div>
  
</template>


<script setup>
// import { debounce } from 'lodash';
import debounce from 'lodash/debounce';
import { useClipboard } from '@vueuse/core'
import { watch } from 'vue';

const emits = defineEmits(['close'])
const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const { $api, $popup } = useNuxtApp()
const props = defineProps({
  room: {
    type: Object
  }
})
var search_user_name = ref('')
var users = ref([])
  

const close = function(){
  emits('close')
}

const searchUser = debounce((event) => {
  search_user_name.value = event.target.value;
  console.log(search_user_name.value)
  users.value = {};
  users.value.items = [];
  searchUsers();

}, 300);
 
const invite = function(user){
  $api.postChatRoomUser(props.room.id,  user.id)
  .then((res) => {                          
    $popup.success('Invited')
    invited_data.value.push(user);
  })
  .catch((err) => {
    $popup.error('Error')
  });
}

const searchUsers = async function(){
  var data = await $api.getGrowers({
    keyword: search_user_name.value,
    start: 0,
    limit: 40,
  })
  console.log(data);
  users.value.items.push(...data.items);
}

const remove = function(user){
  $api.deleteChatRoomUser(props.room.id,  user.id)
  .then((res) => {                          
    $popup.success('Deleted')
    invited_data.value = invited_data.value.filter((invited_user) => invited_user.id != user.id)
  })
  .catch((err) => {
    $popup.error('Error')
  });
}
 

const loadData = async function(){
  console.log('chat users');
  var data = await $api.getChatRoomUsers(props.room.id, 0, 200)
  return data? data: [];
}


const { pending: is_loading,  data: invited_data } = await useLazyAsyncData('invited_data', async () => await loadData())



// watch(() => search_user_name, (newId, oldId) => {
//   users.value = [];
//   searchUsers();
// }, { deep: true })


onBeforeUnmount(() => {
  invited_data.value = [];
})
</script>
 


<style scoped>
  

/* MANAGE */
.manage {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}
   
.manage>.title {
  text-align: center;
  padding: 10px 20px 10px 20px;
  font-size: 1.3rem;
}
.manage>.title i{
  font-size: 1.1rem;
}

.users{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}
.users .user{
  display: flex;
  align-items: center;
  gap: 10px;
}
.users .user .avatar{
  border-radius: 100px;
  aspect-ratio: 1/1;
  width: 40px;
}
.users .user .name{

}
.users .user .act{
  margin-left: auto;
}

  
</style>