<template>

  <div :class="{rooms: true, worked: worked}">

    <div v-if="useAuth().isAuth()" class="room_types">
      <a :class="'item search ' + (tab == 'search' ? ' active ' : '')" @click="toggleTab('search')"><span class="nm"><i class="icon-search"></i></span></a>
      <a :class="'item normal' + (tab == 'common' ? ' active ' : '')" @click="toggleTab('common')"><span class="nm">Common</span></a>
      <a :class="'item normal' + (tab == 'business' ? ' active ' : '')" @click="toggleTab('business')"><span class="nm">Business</span></a>
    </div>

    <div v-if="common" :class="'room_types_tab' + (tab == 'search' ? ' active ' : '')" data-tab="search">
      <div class="room_group_content_search">
        <ChatSearch
          @select="open(opt)"
          />  
      </div>
    </div>

    <div v-if="common" :class="'room_types_tab' + (tab == 'common' ? ' active ' : '')" data-tab="common">
      <div class="room_group_content_common rooms-list">
        <template
          v-for="(opt, opt_key) in common"
          >
          <ChatRoomsItem
            :avatar="opt.avatar"
            :name="opt.name"
            :cnt="opt.cnt_update"
            :online="opt.is_online ? true : false" 
            @click="open(opt)"
            />
        </template>

        <client-only>    
          <InfinityScroll 
            v-if="is_ended_common == false"
            :is-loading="false"
            ref="el_infinity_scroll_common"/>
          <!-- <infinite-loading @infinite="loadMoreCommon"></infinite-loading> -->
        </client-only>
      </div>
    </div>

    <div v-if="business" :class="'room_types_tab' + (tab == 'business' ? ' active ' : '')" data-tab="business">

      <div class="room_group_content_business rooms-list">
        <template
          v-for="(opt, opt_key) in business"
          >
          <ChatRoomsItem
            :avatar="opt.avatar"
            :name="opt.name"
            :cnt="opt.cnt_update"
            :online="opt.is_online ? true : false" 
            @click="open(opt)"
            />
        </template>
        <client-only>
          <InfinityScroll 
            v-if="is_ended_business == false"
            :is-loading="false"
            ref="el_infinity_scroll_busines"/>
          <!-- <infinite-loading @infinite="loadMoreBusiness"></infinite-loading> -->
        </client-only>
      </div>
 
    </div>
        
  </div>
  
</template>


<script setup>

import { useIntersectionObserver } from '@vueuse/core'

const emits = defineEmits(['open', 'loadcommon', 'loadbusiness', 'toggletab'])
const props = defineProps({
  common: {
    type: Array
  },
  commoncnt: {
    type: Number
  },
  business: {
    type: Array
  },
  businesscnt: {
    type: Number
  },
  worked: {
    type: Boolean
  },
  tab: {
    type: String,
    default: 'common'
  }
})
const is_ended_common = ref(false)
const is_ended_business = ref(false)
const el_infinity_scroll_common = ref(null)
const el_infinity_scroll_busines = ref(null)
// const tab = ref('common')

const toggleTab = (tab_id) => {
  // tab.value = tab_id
  emits('toggletab', tab_id)
}

const open = (room) => {
  emits('open',room)
}

const loadMoreCommon = (state) => {
  emits('loadcommon',state)
}

const loadMoreBusiness = (state) => {
  emits('loadbusiness',state)
}


useIntersectionObserver(
  el_infinity_scroll_common,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      loadMoreCommon();
    }
  },
  { distance: 10 } 
)

useIntersectionObserver(
  el_infinity_scroll_busines,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      loadMoreBusiness();
    }
  },
  { distance: 10 } 
)

</script>
 


<style scoped>
  
  
/* ROOMS LIST */
.rooms {
  padding: 10px 0px;
  background-color: var(--un-background-color-gray);
  height: calc(100% - 120px);
  text-align: center;
  overflow: auto;
}

.worked>.rooms {
  overflow-x: auto;
  white-space: nowrap;
  height: 110px;
}

.rooms > .room_types{
    display: flex;
}
.rooms > .room_types .item{
    width: 50%;
    flex-shrink: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 2px var(--un-element-color) solid;
    cursor:pointer;
    position:relative;
    justify-content: center;
}
.rooms > .room_types .item.search{
  width: 60px;
}
.rooms > .room_types .item.normal{
  width: calc((100% - 60px) / 2);
}
.rooms > .room_types .item .nm{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: calc(100% - 20px);
}
.rooms > .room_types .item .badge{
      border-radius: 20px;
      background-color: #86b85e;
      display: inline-block;
      /* position: absolute; */
      /* right: 0px; */
      top: 12px;
      color: white;
      min-width: 17px;
      margin-right: 5px;
      font-size: 0.75rem;
      text-align: center;
      box-shadow: 0px 0px 5px white;
}
.rooms > .room_types .item:hover,
.rooms > .room_types .item.active{
    border-bottom: 2px var(--un-primary-back-color) solid;
    color: var(--un-primary-back-color);
}
.rooms > .room_types_tab{
    display: none;
}
.rooms > .room_types_tab.active{
    display: block;
    padding-top: 15px;
}

.worked>.rooms .room_load_more {
  display: none;
}

.rooms .rooms-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
.rooms .room_item {
  display: inline-block;
  text-align: center;
  margin: 4px;
  cursor: pointer;
  width: 70px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.rooms .room_item .avatar {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
}

.rooms .room_item .name {
  color: var(--un-text-color);
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  min-height: 17px;
}

.rooms .room_item .name .is_online {
  display: none;
  width: 5px;
  height: 5px;
  background-color: #86b85e;
  vertical-align: middle;
  margin-right: 4px;
  border-radius: 10px;
}

.rooms .room_item .name .is_online.yes {
  display: inline-block;
}

.rooms .room_item .count {
  border-radius: 20px;
  background-color: #86b85e;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0px;
  color: white;
  min-width: 17px;
  font-size: 0.75rem;
  text-align: center;
  box-shadow: 0px 0px 5px white;
}

.rooms .room_item .avatar_img {
  height: 50px;
  width: 50px;
  border-radius: 60px; 
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(170, 170, 170, 0.65) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(170, 170, 170, 0.65) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(170, 170, 170, 0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6aaaaaa', GradientType=1);
}

 
  .rooms.worked {
    overflow-x: inherit;
    white-space: inherit;
    width: 51%;
    height: calc(100% - 76px);
  }

  .rooms.worked .room_load_more {
    display: block;
  }


@media (max-width: 768px) {
  .rooms.worked {
    display: none;
  }
}

</style>