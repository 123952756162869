<template>

  <div :class="'back ' + screen" @click="close(false)">
    <i class="icon-angle-left"></i>
  </div>
  
</template>


<script setup>

const emits = defineEmits(['close'])

const props = defineProps({
  screen: {
    type: String,
    default: 'all'
  }
})

const close = function(){
  emits('close', false);
}

</script>
 


<style scoped>
  
.back{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  font-size: 1.1rem;
  left: 0;
  top: 6px;
  cursor: pointer;
}

.back.mobile{
  display: none;
}

.back.desktop{
  display: flex;
}


@media (max-width: 768px) {
 
  .back.mobile {
    display: flex;
  }

  .back.desktop{
    display: none;
  }
  
}



</style>